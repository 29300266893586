import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {css} from '@emotion/react';
import {mobile, tablet} from '~styles/global';
import {graphql} from 'gatsby';
import DataViewer from '~utils/DataViewer';
import useBreakpoint from '~utils/useBreakpoint';
import ScrollSelector from '~components/ScrollSelector';
import {useSiteState} from '~context/siteContext';
import Seo from '~components/Seo';
import ProjectGridMobile from '~components/ProjectGridMobile';

const Directors = ({data}) => {
	const page = {...data?.sanityPonyShow, ...data?.allSanityDirector};
	const {isMobile, loaded} = useBreakpoint();
	const [siteState, setSiteState] = useSiteState();

	useEffect(() => {
		setSiteState((prevState) => ({
			...prevState,
			pageTitle: page.title,
		}));
	}, []);

	return (
		<>
			<Seo title={page.title} metaTitle={page.seo?.metaTitle} description={page.seo?.metaDescription} image={page.seo?.socialImage} />
			{loaded && (
				<Wrap>
					{isMobile ? <ProjectGridMobile data={page} bgColor="black" /> : <ScrollSelector data={page} bgColor="black" />}
					<DataViewer data={page} name="page" />
				</Wrap>
			)}
		</>
	);
};

const Wrap = styled.div`
	background-color: var(--black);
`;
Directors.propTypes = {
	data: PropTypes.object,
};

export const query = graphql`
	query PonyShowQuery($id: String) {
		sanityPonyShow(id: {eq: $id}) {
			title
			slug {
				current
			}
			seo {
				...seo
			}
			projects: directors {
				_type
				title
				isSubItem
				slug {
					current
				}
				thumbnail {
					...thumbnail
				}
			}
		}
		# allSanityDirector {
		# 	projects: nodes {
		# 		_type
		# 		title
		# 		slug {
		# 			current
		# 		}
		# 		thumbnail {
		# 			...thumbnail
		# 		}
		# 	}
		# }
	}
`;

export default Directors;
